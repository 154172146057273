import React from "react";
import "./TimeLine.css";
import DateService from '../service/DateService'; // Import the styles (CSS provided below)

// Define the type for timeline items (for TypeScript users, optional)
interface TimelineItem {
    title: string; // Required: Date or description
    cardTitle: string; // Required: Title of the timeline item
    cardSubtitle?: string; // Optional: Subtitle with details
    url?: string; // Optional: Link for more information
}

// Props for the Timeline component
interface TimelineProps {
    items: TimelineItem[]; // Array of timeline items
}

// React Functional Component
const Timeline: React.FC<TimelineProps> = ({ items }) => {
    return (
        <div className="timeline">
            {items.map((item, index) => (
                <div className="timeline-item" key={index}>
                    <div className="timeline-item-content">
                        <h5>{item.cardTitle}</h5>
                        <h6>{DateService.toUIDate(item.title)}</h6>
                        {item.cardSubtitle && <p>{item.cardSubtitle}</p>}
                        {item.url && (
                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                                View on map
                            </a>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Timeline;