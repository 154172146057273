import React, { Suspense } from 'react';
import 'leaflet/dist/leaflet.css';
import Header from "../shared/Header";
import Footer from "../shared/Footer";

// @ts-ignore
function PagePrivacy() {
    document.title = 'Mufleet - Privacy';

    return (
        <Suspense
            fallback={
                <p>
                    loading ...
                </p>
            }
        >
            <div className="container">
                <Header/>
                <div className="row">
                       <div className="col-md-2">
                       </div>
                    <div className="col-md-8">
                       <div className="container">
                          <p><strong>Last updated: December 31, 2024</strong></p>
                              <p>This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                              <p>We use Your Personal Data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
                              <hr />
                              <h3><strong>Interpretation and Definitions</strong></h3>
                              <h4><strong>Interpretation</strong></h4>
                              <p>The words with the initial letter capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.</p>
                              <h4><strong>Definitions</strong></h4>
                              <p>For the purposes of this Privacy Policy:</p>
                              <ul>
                                  <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
                                  <li><strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party.</li>
                                  <li><strong>Application</strong> refers to mufleet, the software program provided by the Company.</li>
                                  <li><strong>Company</strong> (referred to as &quot;We,&quot; &quot;Us,&quot; or &quot;Our&quot;) refers to padjis, Sankt Peders Gade 11, 9400 N&oslash;rresundby, Denmark.</li>
                                  <li><strong>Country</strong> refers to Denmark.</li>
                                  <li><strong>Device</strong> means any device that can access the Service such as a computer, cellphone, or tablet.</li>
                                  <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
                                  <li><strong>Service</strong> refers to the Application.</li>
                                  <li><strong>Service Provider</strong> means a third-party company that processes data on behalf of the Company.</li>
                                  <li><strong>Usage Data</strong> refers to data collected automatically, such as browser type or duration of a page visit.</li>
                                  <li><strong>You</strong> means the individual accessing the Service.</li>
                              </ul>
                              <hr />
                              <h3><strong>Collecting and Using Your Personal Data</strong></h3>
                              <h4><strong>Types of Data Collected</strong></h4>
                              <p><strong>Personal Data:</strong> While using Our Service, We may ask You to provide certain information, including:</p>
                              <ul>
                                  <li>Email address</li>
                                  <li>First and Last name</li>
                                  <li>Usage Data</li>
                              </ul>
                              <p><strong>Usage Data:</strong> Automatically collected when using the Service and may include details such as:</p>
                              <ul>
                                  <li>Device IP address, browser type, and version</li>
                                  <li>Pages visited, time spent on pages, and diagnostic data</li>
                              </ul>
                              <p><strong>Location Data:</strong> With your prior consent, We may collect location data to provide certain features. You can enable or disable location services through device settings.</p>
                              <hr />
                              <h3><strong>Use of Your Personal Data</strong></h3>
                              <p>The Company may use Your data for purposes including:</p>
                              <ul>
                                  <li>Providing and maintaining the Service.</li>
                                  <li>Managing Your Account as a registered user.</li>
                                  <li>Contacting You with updates or communications.</li>
                                  <li>Sharing with Service Providers to analyze and improve the Service.</li>
                                  <li>Evaluating or conducting business transfers.</li>
                              </ul>
                              <h4><strong>Managing OAuth Permissions</strong></h4>
                              <p>You can revoke the permissions granted to our application at any time by visiting your
                                 Google&nbsp;Account&nbsp;Permissions.</p>
                              <hr />
                              <h3><strong>Retention and Deletion of Personal Data</strong></h3>
                              <p>We retain Personal Data only for as long as necessary to fulfill the purposes outlined in this policy, including to comply with legal obligations.</p>
                              <p>You may request deletion of Your Personal Data by contacting Us or accessing Your Account settings. However, certain data may be retained as required by law.</p>
                              <hr />
                              <h3><strong>Sharing of Data</strong></h3>
                              <p>We may share Your information:</p>
                              <ul>
                                  <li>With Service Providers for business operations.</li>
                                  <li>During business transfers like mergers or acquisitions.</li>
                                  <li>With affiliates under strict data-protection obligations.</li>
                                  <li>With Your consent for specific purposes.</li>
                              </ul>
                              <hr />
                              <h3><strong>Children&rsquo;s Privacy</strong></h3>
                              <p>Our Service does not address children under the age of 13. If We collect data from a child under 13 without parental consent, We will delete it promptly.</p>
                              <hr />
                              <h3><strong>Data Transfers</strong></h3>
                              <p>Your Personal Data may be transferred and stored outside Your country of residence. Such transfers are conducted in compliance with data protection laws, including GDPR where applicable.</p>
                              <hr />
                              <h3><strong>Data Security</strong></h3>
                              <p>While We use commercially reasonable methods to secure Your Personal Data, We cannot guarantee absolute security.</p>
                              <hr />
                              <h3><strong>Your Rights</strong></h3>
                              <p>You have the right to:</p>
                              <ul>
                                  <li>Access, update, or delete Your Personal Data.</li>
                                  <li>Withdraw Your consent for data collection or sharing.</li>
                                  <li>Export Your data where applicable.</li>
                              </ul>
                              <hr />
                              <h3><strong>Changes to This Privacy Policy</strong></h3>
                              <p>We may update this Privacy Policy periodically. We will notify You of significant changes via email or a prominent notice on the Service at least 30 days before the changes take effect.</p>
                              <hr />
                              <h3><strong>Contact Us</strong></h3>
                              <p>If you have any questions about this Privacy Policy, please contact us:</p>
                              <ul>
                                  <li>By email: <strong>help@mufleet.com</strong></li>
                              </ul>
                              <hr />
                              <p><br /></p>
                       </div>
                    </div>
                    <div className="col-md-2">
                       </div>
                </div>
                <Footer />
            </div>
        </Suspense>
    );
}

export default PagePrivacy;
