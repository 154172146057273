class QueryStringService {
    public static getString(key: string) {
        const queryParams = new URLSearchParams(window.location.search);
        let res: string | null = queryParams.get(key);
        if (res != null) {
            console.debug('Found key ' + key + ' and value ' + res);
        }
        return res;
    }
}

export default QueryStringService;
