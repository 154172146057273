import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Page404 from './page/Page404';
import PagePrice from "./page/PagePrice2";
import PageWelcome from "./page/PageWelcome";
import PagePrivacy from "./page/PagePrivacy";
import PageNoData from "./readme/PageNoData";
import PageDemo from './page/PageDemo';
import PageFeatures from './readme/PageFeatures';

export interface IApplicationProps {}

const App: React.FunctionComponent<IApplicationProps> = (props) => {
    return (
        <div className="container-fluid">
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route index element={<PageWelcome />} />
                        <Route path="/price" element={<PagePrice />} />
                        <Route path="/privacy" element={<PagePrivacy />} />
                        <Route path="/demo" element={<PageDemo />} />
                        <Route path="/features" element={<PageFeatures />} />
                        <Route path="/nodata" element={<PageNoData />} />
                        <Route path="*" element={<Page404 />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
