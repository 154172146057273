import React, { Suspense, useLayoutEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import QueryStringService from '../service/QueryStringService';
import DateService from '../service/DateService';
import Timeline from '../class/TimeLine';

const mapInfosToRows = (infos: any[]) => {
    return infos.map((info) => {
        const date = DateService.toUIDate(info.timestamp);
        if (info.states !== undefined && info.states.length > 0) {
            return {
                title: date,
                cardTitle: 'state',
                cardSubtitle: info.states.join(', ')
            };
        } else {
            const subtitle = `latitude: ${info.latitude} / longitude: ${info.longitude}`;
            return {
                title: date,
                cardTitle: info.address ? info.address : 'Click to view',
                url: `https://www.google.com/maps?q=${info.latitude},${info.longitude}`,
                cardSubtitle: subtitle
            };
        }
    });
};

// @ts-ignore
function PageTrack() {
    document.title = 'Mufleet - Track';
    const [id, setId] = useState('');
    const [initialId] = useState(QueryStringService.getString("id")||'');
    const [response, setResponse] = useState([]);

    useLayoutEffect(() => {
        let tmpId = QueryStringService.getString("id")||'';
        const init = () => {
            if(tmpId !== ''){
                var query = 'https://api.mufleet.com/public/v1/shipment?id=' + tmpId;
                fetch(query, {
                    method: 'GET'
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if(res.length === 0){
                            window.location.href = "/error";
                        }
                        // @ts-ignore
                        setResponse(mapInfosToRows(res));
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        };
        init();
    }, [])

    function handleClick() {
        window.location.href = "/track?id=" + id;
    }

    if (initialId === '') {
        return (
            <Suspense fallback={<p>loading ...</p>}>
                <div className="container">
                    <Header />
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col-8 full-height-div">
                            <input className="big-input" type="text" placeholder="Shipment ID" size={50} onChange={event => setId(event.target.value)} />
                            <button className="big-input btn-success" type="button" onClick={handleClick}>Track</button>
                        </div>
                        <div className="col-2"></div>
                    </div>
                </div>
                <Footer />
            </Suspense>
        );
    }else{
        return (
            <Suspense fallback={<p>loading ...</p>}>
                <div className="container">
                    <Header />
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col-8">
                            <div className="col-md-6">{response && <Timeline items={response} />
                            }</div>
                        </div>
                        <div className="col-2"></div>
                    </div>
                </div>
                <Footer />
            </Suspense>
        );
    }

}

export default PageTrack;
